import * as React from "react"
import Helmet from "react-helmet";
import Layout from '../components/Layout'

import { makeStyles } from "@material-ui/styles";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {browserTitle, seoDescription, seoTitle, qaData, keywords} from "../data/scienceqa";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Divider from "@mui/material/Divider/Divider";
import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
import {useState} from "react";
import {Add, Close} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse/Collapse";
import {BlackBack, SubTitleEnBox, SubTitleWhiteBox} from "../components/Common/Base";
import ProjectHeader from "../components/Common/ProjectHeader";

const useStyles = makeStyles({
    flexCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mb25: {
      marginBottom: 25,
    },
    // box5Content: {
    //   backgroundSize: 'contain',
    //   backgroundPosition: 'right center',
    //   backgroundRepeat: 'no-repeat',
    //   backgroundImage: `url(${require('../images/about/07.png').default})`,
    //   height: '40vw',
    //   maxHeight: 550,
    // },
    honor1: {
      color: 'rgba(255,255,255,0.6)',
    }
  });

// const MainHeadBox = styled(Box)(({theme}) => {
//   return {
//     position: 'relative',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     backgroundImage: `url(${require('../images/scienceqa/02.jpg').default})`,
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'flex-end',
//     height: '60vw',
//   }
// });

const Contact = (props) => {
  const { location } = props;
  const classes = useStyles();

  const [open, setOpen] = useState();

  const handleClick = (index) => () => {
    if(index === open) {
      setOpen(null);
    } else {
      setOpen(index);
    }
  };

  const eleShow = React.useRef();

  const handleNextClick = () => {
    eleShow.current && eleShow.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  };

  return (
    <Layout location={location}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={keywords} />
        <title>{browserTitle}</title>
      </Helmet>
      <ProjectHeader
        // backVideo={require('../../images/products/city3d/01.mp4').default}
        backImg={require('../images/scienceqa/03.jpg').default}
        backVideo={'scienceqa/01.m3u8'}
        title={'科学问答'}
        titleEn={'Science Q&A'}
        // titleEn2={'Urban Weather Digital Twin System'}
        // subTitle={'三维城市气象仿真系统'}
        gridShow
        handleNextClick={handleNextClick}
      />
      <Container
        ref={eleShow}
        maxWidth="xl"
        sx={{
          pt: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
          pb: {
            xs: 4,
            sm: 8,
            md: 16,
            lg: 24,
          },
        }}
      >
        <List>
          <Divider component="li" />
          {
            qaData.map((item, index) => {
              return (
                <React.Fragment key={'q-' + index}>
                  <ListItemButton onClick={handleClick(index + 1)}>
                    <ListItemText primary={item.q} secondary={item.qEn} />
                    {open === (index + 1) ? <Close /> : <Add />}
                  </ListItemButton>
                  <Divider component="li" />
                  <Collapse in={open === (index + 1)} timeout="auto" unmountOnExit>
                    <Box
                      sx={{
                        p: {
                          xs: 2,
                          sm: 4,
                          md: 6,
                          lg: 8,
                        }
                      }}
                    >
                      <SubTitleWhiteBox
                        variant="subtitle1"
                        gutterBottom>
                        {
                          item.a
                        }
                      </SubTitleWhiteBox>
                      <SubTitleEnBox
                        variant="subtitle1"
                        gutterBottom
                      >
                        {
                          item.aEn
                        }
                      </SubTitleEnBox>
                    </Box>
                    <Divider />
                  </Collapse>
                </React.Fragment>
              )
            })
          }
        </List>
      </Container>
      <Container
        maxWidth="xl"
        sx={{
          p: 2
        }}
      >
      </Container>
    </Layout>
  )
}


export default Contact;
