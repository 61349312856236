export const seoTitle = '科学问答 | 赑玄阁';
export const seoDescription = '科学问答包含气象科学和气象预测模型的简单介绍';
export const browserTitle = '科学问答 | 赑玄阁';
export const keywords = '赑玄阁, 科学, 气象科学, 气象预测, 气象预测模型';


export const qaData = [
  {
    q: '什么是时间、空间分辨率?',
    qEn: 'What is time or spatial resolution?',
    a: '泛指量测或显示系统对细节的分辨能力。此概念可以用时间、空间等领域的量测。日常用语中分辨率多用于影像的清晰度。解析度越高代表影像品质越好，越能表现出更多的细节；但相对的，因为纪录的资讯越多，档案也就会越大。我们提供的是高清的 “时间、空间分辨率” 的服务。',
    aEn: 'A general term for the ability of a measurement or display system to resolve details. This concept can be used for measurements in the fields of time and space. The term "resolution" is used in everyday language to refer to the sharpness of an image. The higher the resolution means the better the image quality and the more details can be expressed; however, correspondingly, because the more information is recorded, the larger the file will be. We provide "temporal and spatial resolution" services in high definition.',
  },
  {
    q: '什么是天气模拟?',
    qEn: 'What is Numeric Weather Prediction?',
    a: '是一种根据大气的数学模型、利用当前天气状况作为输入数据而作出天气预报的手段，為現代天气预报之主流。尽管早在1920年代时已有人作出过尝试并取得一定成果，但直到计算机模拟出现之后，数值天气预报才成为一种切实可行的实时天气预报方法。为了得到準確的预测结果，世界多國都使用超级电脑来进行复杂的计算。许多天气预测模型，无论本身是全球性或是区域性的，或多或少都为全球天气预报提供帮助。将这些模型一同使用，能够降低天气预报的不确定性，以及将可预测的时间范围延伸到更远。',
    aEn: 'Numerical weather prediction (NWP) uses mathematical models of the atmosphere and oceans to predict the weather based on current weather conditions. Though first attempted in the 1920s, it was not until the advent of computer simulation in the 1950s that numerical weather predictions produced realistic results. A number of global and regional forecast models are run in different countries worldwide, using current weather observations relayed from radiosondes, weather satellites and other observing systems as inputs.',
  },
  {
    q: '什么是仿真?',
    qEn: 'What is simulation?',
    a: '计算机常被用来运行仿真模型。仿真也被用于对自然系统或人造系统的科学建模以获取深入理解。仿真可以用来展示可选条件或动作过程的最终结果。仿真也可用在真实系统不能做到的情景，这是由于不可访问、太过于危险、不可接受的后果、或者设计了但还未实现、或者压根没有被实现等。仿真的主要论题是获取相关选定的关键特性与行为的有效信息源，仿真时使用简化的近似或者假定，仿真结果的保真度与有效性。',
    aEn: 'A simulation is the imitation of the operation of a real-world process or system over time. Simulations require the use of models; the model represents the key characteristics or behaviors of the selected system or process, whereas the simulation represents the evolution of the model over time. Often, computers are used to execute the simulation.',
  },
  {
    q: '什么是预测模型？',
    qEn: 'What is the forecast model? ',
    a: '天气取决于数百个因素：风速、云量、大气压力、湿度、降水、地形等。所有这些因素都可以被量化：米/秒、毫米汞柱等。如果你把这些变量放入一个方程，你可以创建一个数学天气模型。 \n' +
      '\n' +
      '一个预测模型可能是错误的。它不可能考虑到所有的大气因素。而且，在数据输入过程中可能会出现意外的错误。例如，一个5.6米/秒的风速被传感器认为是5.7米/秒。\n' +
      '\n' +
      '正如你所看到的，预报并不总是能正确预测事件。这就是为什么气象学家在预测中使用所谓的概率方法—他们检查集合模型',
    aEn: 'The weather depends on hundreds of factors: wind speed, cloudiness, atmospheric pressure, humidity, precipitation, terrain, etc. All of these factors can be quantified: meters per second, millimeters of mercury, etc. If you put these variables into an equation, you can create a mathematical weather model.  \n' +
      '\n' +
      'A forecast model may be wrong. It is impossible to take into account all of the atmospheric factors. Also, there could be an accidental error during data input. For instance, a wind speed of 5.6 m/sec was perceived as 5.7 m/sec by the sensor. \n' +
      '\n' +
      'As you can see, the forecast does not always predict the events correctly. This is why the meteorologists use a so-called probabilistic approach in forecasting-they examine the ensemble models\n',
  },
];
